import {
  type ListMode as ListModeType,
  type ContactBatchCreationMethod as ContactBatchCreationMethodType,
} from "@openqlabs/drm-db";

export const ListMode: Record<string, ListModeType> = {
  LIGHT: "LIGHT",
  ULTRALIGHT: "ULTRALIGHT",
  DEEP: "DEEP",
};

export const ContactBatchCreationMethod: Record<
  string,
  ContactBatchCreationMethodType
> = {
  ADD: "ADD",
  IMPORT: "IMPORT",
  LEAD_DISCOVERY: "LEAD_DISCOVERY",
  FORKS_DISCOVERY: "FORKS_DISCOVERY",
  ADD_CONTACTS_BY_LIST: "ADD_CONTACTS_BY_LIST",
  BOOST: "BOOST",
};
