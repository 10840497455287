import axios from "axios";
import { Authentication } from "@openqlabs/utils";
import { isGithubRateLimitsResponse } from "./types";
import { mockFullRateLimit } from "./mockFullRateLimitResponse";

export async function fetchRateLimits(authenticator: Authentication.Manager) {
  try {
    const response = await axios.get("https://api.github.com/rate_limit", {
      headers: {
        Authorization: `Bearer ${authenticator.getAccessToken()}`,
      },
    });
    const data = response.data;

    if (!isGithubRateLimitsResponse(data)) {
      return mockFullRateLimit.resources;
    }

    return data.resources;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const statusCode = error.response?.status;
      const errorMessage = error.response?.data;
      const accessToken = authenticator.getAccessToken();
      console.error(
        `Error fetching rate limits: GraphQL API Error (${statusCode}): ${errorMessage} -> (Access Token: ${accessToken})`
      );
    } else if (error instanceof Error) {
      console.error("Error fetching rate limits: ", error.message);
    }
    return mockFullRateLimit.resources;
  }
}
