import chalk from "chalk";
export class Logger {
  private shortAccessToken: string;
  private currentEvaluationId: string | null = null;
  private shortCurrentEvaluationId: string | null = null;
  private LOG_DISABLED = true;

  constructor(accessToken: string, LOG_DISABLED = false) {
    this.shortAccessToken = accessToken
      ? accessToken.slice(0, 7) + "..." + accessToken.slice(-4)
      : "no access token";
    this.LOG_DISABLED = LOG_DISABLED;
  }

  setCurrentJobId(id: string | null) {
    this.currentEvaluationId = id;
    this.shortCurrentEvaluationId = id
      ? id.slice(0, 4) + "..." + id.slice(-4)
      : null;
  }

  setAccessToken(accessToken: string) {
    this.shortAccessToken =
      accessToken.slice(0, 7) + "..." + accessToken.slice(-4);
  }

  log(
    logFunction: (...args: unknown[]) => void,
    colorFunction: (text: string) => string,
    ...args: string[]
  ) {
    if (this.LOG_DISABLED) {
      return;
    }
    if (this.currentEvaluationId) {
      logFunction(
        colorFunction(
          `[${this.shortAccessToken}][${this.shortCurrentEvaluationId}]`
        ),
        ...args
      );
    } else {
      logFunction(colorFunction(`[${this.shortAccessToken}]`), ...args);
    }
  }

  info(...args: unknown[]) {
    this.log(console.log, chalk.blueBright, ...(args as string[]));
  }

  warn(...args: unknown[]) {
    this.log(console.warn, chalk.yellow, ...(args as string[]));
  }

  error(...args: unknown[]) {
    this.log(console.error, chalk.red, ...(args as string[]));
  }
  dev(...args: unknown[]) {
    console.log(...args);
  }
}
